export function isMobile() {
    return typeof window !== "undefined" ? (window.innerWidth < 768) : false
}

export function properCase(str) {
    return str
      .split('-')
      .map(s => s.slice(0, 1).toUpperCase() + s.slice(1))
      .join(' ')
}

export function resolvePath(path) {
    // if path doesn't contain a question mark (parameter) then drop in 
    if (path.indexOf('?') <= -1) { 
      // if starting characters are a dot-slash ("./"), then just make it a slash!   
      if (path.substring(0,2) == './') path = path.substring(1)

      // if no beginning slash, then put one in!   
      if (path.substring(0,1) != '/') path = `/${path}`

      // if no trailing slash, then put one in!   
      if (path.slice(-1) != '/') path = `${path}/`

      // if path is relative (contains "../") then make it absolute
      if (path.substring(0,2) == '..') {
        // if our current page location is nested by more than 2 levels i.e. /products/prod1/feature/, get the scope of the location for a complete path  
        if (path.split("/").length - 1 > 2) {
            let currentscope = location.pathname.substring(0,location.pathname.lastIndexOf("/",location.pathname.length - 2))
            let targetscope = path.substring(2,path.lastIndexOf("/",path.length - 2))
            if (currentscope == targetscope) {
                path = path.replace("../", `${location.pathname.substring(0,location.pathname.lastIndexOf("/",location.pathname.length - 2))}/`)
            }
            else {
                path = path.replace("..", "")
            }
        }
        else {
            let currentscope = location.pathname.substring(0,location.pathname.lastIndexOf("/",location.pathname.length - 2))
            console.log(currentscope)
            path = path.replace("..", currentscope)
        }
      }
    }

    return path
}